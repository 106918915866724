import * as actions from "./action-type";
import { createAction } from "redux-actions";
import { sendMessage } from "../../connections/websocket";
import { GROUP, TYPE } from "../../commons/constants/websocket";
import { getTenantInfo } from "../../commons/tenant-info";

export const updateCallInfoRequest = createAction(`${actions.UPDATE_CALL_INFORMATION}.REQUEST`);
export const updateCallInfo = (payload, newStatus, channelId) => {
  return async function (dispatch, store) {
    // console.log("updateCallInfo");
    dispatch(updateCallInfoRequest({ ...payload, status: newStatus }));
    sendMessage({
      group: GROUP.CALL,
      type: TYPE.CALL.UPDATE_CALL_INFORMATION,
      payload,
      tenantInfo: getTenantInfo(),
    });
  };
};
