export const START_CONVERSATION = 'BEHAVIOR/START_CONVERSATION';
export const TOGGLE_SUPPORT_WIDGET = 'BEHAVIOR/TOGGLE_SUPPORT_WIDGET';
export const ON_SCROLL_TO_BOTTOM = 'BEHAVIOR/ON_SCROLL_TO_BOTTOM';
export const TOGGLE_CHAT = 'BEHAVIOR/TOGGLE_CHAT';
export const TOGGLE_CALL = 'BEHAVIOR/TOGGLE_CALL';
export const CHECK_MEDIA_DEVICE = 'BEHAVIOR/CHECK_MEDIA_DEVICE';
export const UPDATE_MEDIA_PERMISSION = 'BEHAVIOR/UPDATE_MEDIA_PERMISSION';
export const SELECT_DEVICE = 'BEHAVIOR/SELECT_DEVICE';
export const UPDATE_CALL_STATUS = 'BEHAVIOR/UPDATE_CALL_STATUS';
export const UPDATE_CALL_INFORMATION = 'BEHAVIOR/UPDATE_CALL_INFORMATION';
export const START_CALL = 'BEHAVIOR/START_CALL';
export const REQUEST_ICE_SERVERS = 'BEHAVIOR/REQUEST_ICE_SERVERS';
export const STOP_CALL = 'BEHAVIOR/STOP_CALL';
export const LEAVE_INFORMATION = 'BEHAVIOR/LEAVE_INFORMATION';
export const SEND_TEXT_MESSAGE = 'MESSAGE/SEND_TEXT';
export const SEND_ATTACHMENT = 'MESSAGE/SEND_ATTACHMENT';
export const SOUND_SETTING = 'BEHAVIOR/SOUND_SETTING';
export const SAVE_USER_INFO = 'BEHAVIOR/SAVE_USER_INFO';
export const UPDATE_HAS_TRIGGERED_WELCOME_MESSAGE = 'BEHAVIOR/UPDATE_HAS_TRIGGERED_WELCOME_MESSAGE';
export const TRACK_THE_TRIGGER_EVENT = 'BEHAVIOR/TRACK_THE_TRIGGER_EVENT';