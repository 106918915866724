import * as io from 'socket.io-client';
import { get, debounce } from 'lodash';
import urljoin from 'url-join';
import { getLSVariable, setLSVariable } from '../commons/utils/local-storage';
import { LOCAL_STORAGE_VARIABLES, getLocalStorageVariables } from '../commons/constants/local-storage';
import { getTenantInfo } from '../commons/tenant-info';
import getClientInfo from '../commons/utils/get-client-info';
import { GROUP, TYPE } from '../commons/constants/websocket';
const queuedMessages = [];

let wsClient = null;
const getWSHost = (apiHost) => {
  if (apiHost.indexOf('localhost') >= 0 || apiHost.indexOf('127.0.0.1') >= 0) return apiHost;
  return apiHost.replace('https://', 'https://ws.').replace('http://', 'http://ws.');
};

const sendOrQueueMessage = (wsMessageType, payload) => {
  // console.log(`wsMessageType ${wsMessageType}:`, JSON.stringify(payload));
  const tenantInfo = getTenantInfo();
  // console.log("tenantInfo:", JSON.stringify(tenantInfo));
  if (wsClient && wsClient.socket && wsClient.socket.connected && tenantInfo.conversationId) {
    // Send all messages in queue first if available
    // console.log("Send all messages in queue");
    while (queuedMessages.length > 0) {
      console.log('send message from queue');
      const queuedMessage = queuedMessages.shift();
      queuedMessage &&
        wsClient.emitMessage(queuedMessage.wsMessageType, {
          ...queuedMessage.payload,
          tenantInfo,
        });
    }

    wsClient.emitMessage(wsMessageType, {
      ...payload,
      tenantInfo,
    });
  } else {
    console.log('enqueue the message: ', { wsMessageType, payload });
    // Add to queue
    queuedMessages.push({ wsMessageType, payload });
    // Enable retry
    retrySendQueuedMessages();
  }
};

const retrySendQueuedMessages = (channelId) => {
  console.log('retrySendQueuedMessages');
  const interval = setInterval(() => {
    const tenantInfo = getTenantInfo();
    if (wsClient && wsClient.connected && tenantInfo.conversationId) {
      console.log('send messsages from queues: ', queuedMessages.length);
      while (queuedMessages.length > 0) {
        const queuedMessage = queuedMessages.shift();
        queuedMessage &&
          wsClient.emitMessage(queuedMessage.wsMessageType, {
            ...queuedMessage.payload,
            tenantInfo,
          });
      }
      clearInterval(interval);
    }
  }, 1000);
};

const debounceReconnect = debounce((ws, onConnected) => {
  ws.connected && onConnected && onConnected();
}, 3000);

class WebSocket {
  constructor(host, token, isFromSimulator, channelKey) {
    this.onMessageHandler = null;
    this.onInitWSSuccess = null;
    this.onStartConversationSuccess = null;
    this.isConnected = false; // Important! Don't depend on `wsClient.socket.connected`. This field could be true before the event onConnect
    this.isReconnecting = false;
    const WS_HOST = urljoin(getWSHost(host), '/chatbox');
    this.socket = io(WS_HOST, {
      transports: ['websocket', 'polling'],
      query: {
        token: `${token}`,
      },
      forceNew: true,
      reconnection: true,
      reconnectionDelay: 2000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 10,
      timeout: 30000,
    });

    this.socket.on('connect', async () => {
      console.log('on connect');
      this.connected = true;
      // Check if it is a old conversation
      const channelId = getLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.CHANNEL_ID, channelKey));
      const tenantId = getLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.TENANT_ID, channelKey));
      let conversationId = getLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.CONVERSATION_ID, channelKey));

      if (isFromSimulator || !conversationId || conversationId === 'undefined') {
        this.socket.emit(
          'new-conversation',
          {
            channelId,
            tenantId,
            visitor: await getClientInfo(),
            isFromSimulator,
          },
          (response) => {
            conversationId = get(response, 'id');
            setLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.CONVERSATION_ID, channelKey), conversationId);

            this.onStartConversationSuccess && this.onStartConversationSuccess(conversationId);
          },
        );
      } else {
        this.socket.emit(
          'old-conversation',
          {
            channelId,
            tenantId,
            conversationId,
            visitor: await getClientInfo(),
          },
          (response) => {
            // console.log("response: ", response);
            conversationId = get(response, 'id');
            if (conversationId) {
              setLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.CONVERSATION_ID, channelKey), conversationId);
              this.onStartConversationSuccess && this.onStartConversationSuccess(conversationId);
            }
          },
        );
      }
    });

    this.socket.on('error', (err) => {
      console.log('error on socket:', err);
    });

    this.socket.on('disconnect', (reason) => {
      this.connected = false;
      console.log('WS:socket disconnect:', reason);
      if (reason === 'io server disconnect') {
        this.socket.connect();
        return;
      }
    });

    this.socket.on('message', (data) => {
      this.onMessageHandler && this.onMessageHandler(data);
    });

    this.socket.on('ping', (data) => {
      if (data === 'ping') {
        this.emitMessage('message', 'pong');
      }
    });

    this.socket.on('reconnecting', (attemptNumber) => {
      console.log('WS:on reconnecting, total attemptNumber: ', attemptNumber);
      this.onReconnecting && this.onReconnecting();
    });

    this.socket.on('reconnect_error', (error) => {
      console.log('WS:on reconnect_error: ');
    });

    this.socket.on('reconnect_failed', () => {
      this.connected = false;
      console.log('WS:on reconnect_failed: ');
      this.onConnectFailed && this.onConnectFailed();
    });

    this.socket.on('connect_error', (error) => {
      this.connected = false;
      console.log('WS:on connect_error: ');
    });

    this.socket.on('connect_timeout', (timeout) => {
      console.log('WS:on connect_timeout: ', timeout);
      this.onConnectFailed && this.onConnectFailed();
    });

    this.socket.on('reconnect', (attemptNumber) => {
      this.connected = true;
      console.log('WS:on reconnect after attemptNumber: ', attemptNumber);
      debounceReconnect(this.socket, this.onConnected);
    });
  }

  emitMessage(type, data) {
    if (!this.connected) {
      console.log('emit message when no connection: ', data);
      // Debug root cause show the connection interrupted
      // this.onConnectFailed && this.onConnectFailed();
      return;
    }
    this.socket.emit(type, data);
  }

  onReceiveMessage(handler) {
    // console.log("set onReceiveMessageHandler");
    this.onMessageHandler = handler;
  }

  onStartConversation(handler) {
    this.onStartConversationHandler = handler;
  }

  onResumeConversation(handler) {
    this.onResumeConversationHandler = handler;
  }

  closeConnection() {
    if (this.socket && this.socket.close) {
      this.socket.close();
    }
  }

  disconnect() {
    this.socket.disconnect();
    this.onConnectFailed && this.onConnectFailed();
  }

  connect() {
    this.socket.connect();
    this.onConnected && this.onConnected();
  }

  isWSConnected() {
    return this.socket.connected;
  }
}

export function initWSClient(
  host,
  token,
  channelId,
  isFromSimulator,
  onInitWSSuccess,
  onStartConversationSuccess,
  onConnectFailed,
  onReconnecting,
  onConnected,
) {
  // console.log("initWSClient");
  if (wsClient) return wsClient;
  wsClient = new WebSocket(host, token, isFromSimulator, channelId);
  wsClient.onStartConversationSuccess = onStartConversationSuccess;
  wsClient.onInitWSSuccess = onInitWSSuccess;
  wsClient.onConnectFailed = onConnectFailed;
  wsClient.onReconnecting = onReconnecting;
  wsClient.onConnected = onConnected;
  return wsClient;
}

export function closeWSClient() {
  if (wsClient) {
    wsClient.closeConnection();
    wsClient = null;
  }
}

export function disconnectWSClient() {
  if (!wsClient) return null;
  wsClient.disconnect();
}

export function reconnectWSClient() {
  if (!wsClient) return null;
  !wsClient.isWSConnected() && wsClient.connect();
}

export function sendMessage(message) {
  sendOrQueueMessage('message', message);
}

export function sendWebsiteEvent(eventType, payload) {
  sendOrQueueMessage('website-event', {
    event: { type: eventType, payload },
  });
}

export function triggerStartingPoint(id) {
  sendOrQueueMessage('message', {
    group: GROUP.MESSAGE,
    type: TYPE.MESSAGE.TRIGGER_STARTING_POINT,
    payload: {
      id,
    },
  });
}

export function setMessageHanlder(handler) {
  if (!wsClient) {
    // console.log("Could not set message handler");
    return;
  }
  wsClient.onReceiveMessage(handler);
}

export function setStartConversationHandler(handler) {
  if (!wsClient) return;
  wsClient.onStartConversationHandler(handler);
}

export function setResumeConversationHandler(handler) {
  if (!wsClient) return;
  wsClient.onResumeConversationHandler(handler);
}
