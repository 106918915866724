import { get, size, isEmpty, includes } from 'lodash';
import { MESSAGE_TYPES } from '../constants/message-type';
import { SENDER_TYPES } from '../constants/sender-types';
const defaultIdForSubscriber = 'subscriber';
const defaultIdForBot = 'bot';
const listTypeMessageHide = [MESSAGE_TYPES.QUICK_REPLY, MESSAGE_TYPES.DELAY];

export const handleCorrectMessages = (messages) => {
  if (isEmpty(messages)) return [];
  const lastPosition = size(messages) - 1;
  messages = (messages || []).map((message, index) => {
    //correct senderId
    // let senderId = get(message, 'senderId') ? get(message, 'senderId') : get(message, 'senderType') === 1 ? defaultIdForSubscriber : defaultIdForBot;

    const senderType = get(message, 'senderType');
    // if (!senderType) {
    //   console.log('senderType in: ', JSON.stringify(message));
    // }
    let senderId =
      senderType === SENDER_TYPES.ADMIN || senderType === SENDER_TYPES.BOT
        ? defaultIdForBot
        : senderType === SENDER_TYPES.SUBSCRIBER
        ? defaultIdForSubscriber
        : get(message, 'senderId')
        ? get(message, 'senderId')
        : defaultIdForSubscriber;
    // console.log(`senderType: ${senderType} - senderId: ${senderId}`);

    //QuickReply, Typing only show when they are at last message. They will be hidden if there are new coming messages or user select the button on quickReply
    if (includes(listTypeMessageHide, get(message, 'message.type')) && index !== lastPosition) {
      return {
        ...message,
        isHideMessage: true,
        senderId,
      };
    }
    return {
      ...message,
      senderId,
    };
  });
  return messages;
};
