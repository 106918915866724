import { createReducer } from '../../commons/utils/store';
import { handleCorrectMessages } from '../../commons/utils/correct-message';
import { MESSAGE_STATUS } from '../../commons/constants/message-status';
import { SENDER_TYPES } from '../../commons/constants/sender-types';
import {
  sendTextMessageRequest,
  receiveMessageFromAdmin,
  sendAttachmentRequest,
  sendAttachmentSuccess,
  sendAttachmentError,
  getMessagesRequest,
  getMessagesSuccess,
  getMessagesError,
  resetNumberOfNewMessages,
  receiveCreatedMessageNotification,
  toggleChatRequest,
  onScrollToBottom,
} from '../actions';
import { isEmpty, pick, get, find, unionBy, size, findLastIndex } from 'lodash';
const MAX_NUMBER_OF_MESSAGES = 20;
const defaultIdForSubscriber = 'subscriber';
const defaultIdForBot = 'bot';
const getSenderId = (message) => {
  if (!message) return;
  if (message.senderType === SENDER_TYPES.BOT) {
    return defaultIdForBot;
  }

  if (message.senderType === SENDER_TYPES.SUBSCRIBER) {
    return defaultIdForSubscriber;
  }

  return message.adminId || defaultIdForBot;
};
function getMessageFromPayload(payload) {
  const type = get(payload, 'type');
  if (type === 'text') {
    return {
      ...pick(payload, ['id', 'type', 'status', 'externalId']),
      content: get(payload, 'content.text') || get(payload, 'content'),
    };
  }
  return pick(payload, ['id', 'type', 'status', 'content', 'externalId']);
}

function shortifyMessages(messages) {
  // return messages;
  if (!messages) return [];
  if (size(messages) > MAX_NUMBER_OF_MESSAGES) {
    // console.log('messages before: ', size(messages));
    const results = messages.slice(size(messages) - MAX_NUMBER_OF_MESSAGES);
    // console.log('messages after: ', size(results));
    return results;
  }
  return messages;
}

function mergeOrAppendNewMessages(oldMessages, newMessages) {
  let result = unionBy(oldMessages, newMessages, (m) => m.message.id).sort((a, b) => a.message.id - b.message.id);
  // console.log('mergeOrAppendNewMessages: ');
  // console.log('newMessages: ', JSON.stringify(newMessages));
  // console.log('oldMessages: ', JSON.stringify(oldMessages));
  // Merge new fields to the existing one
  for (const [i, item] of result.entries()) {
    if (item.message.id) {
      const foundItem = find(newMessages, (m) => m.message.id === item.message.id);
      if (foundItem) {
        // console.log('found item: ', item.message.id);
        result[i] = {
          ...item,
          senderType: get(foundItem, 'senderType') || get(item, 'senderType'), // For hot fix
          message: {
            ...item.message,
            ...foundItem.message,
          },
        };
      } else {
        // console.log('not found: ', item.message.id);
      }
    }
  }

  return handleCorrectMessages(result);
}

function getNumberOfNewMessages(messages) {
  let numberOfNewMessages = 0;
  const lastMessageRead = findLastIndex(messages, ['status', MESSAGE_STATUS.READ]);
  (messages || []).forEach((m, index) => {
    if (index > lastMessageRead && m.senderId !== 'subscriber' && get(m, ['message', 'type']) !== 'delay') {
      numberOfNewMessages = numberOfNewMessages + 1;
    }
  });
  return numberOfNewMessages;
}

const initialState = {
  rawMessages: [],
  messages: [],
  participants: [
    {
      id: defaultIdForBot,
      name: 'bot',
      avatar: '',
      side: 'left',
      isShowAvatar: true,
    },
    {
      id: defaultIdForSubscriber,
      name: 'user01',
      avatar: '',
      side: 'right',
      isShowAvatar: false,
    },
  ],
  isLoadingMessage: false,
  isLoadingMessageHistory: false,
  numberOfNewMessages: 0,
};

const messageReducer = {
  [onScrollToBottom]: (state) => {
    // console.log('onScrollToBottom');
    if (state.isLoadingMessageHistory) {
      return state;
    }
    return { ...state, messages: shortifyMessages(state.messages) };
  },
  [toggleChatRequest]: (state, { payload }) => {
    return {
      ...state,
      numberOfNewMessages: payload === true ? 0 : getNumberOfNewMessages(state.messages),
    };
  },
  [sendTextMessageRequest]: (state, { payload }) => {
    const newMessages = [
      ...state.messages,
      {
        senderId: defaultIdForSubscriber,
        senderType: SENDER_TYPES.SUBSCRIBER,
        message: getMessageFromPayload(payload),
      },
    ];

    return {
      ...state,
      messages: newMessages,
      numberOfNewMessages: getNumberOfNewMessages(newMessages),
    };
  },
  [receiveCreatedMessageNotification]: (state, { payload }) => {
    const { id, status, message } = payload;
    return {
      ...state,
      messages: state.messages.map((item) => {
        if (item.message.id) return item;
        if (get(item, 'message.externalId') === get(message, 'externalId')) {
          return {
            ...item,
            message: getMessageFromPayload({ id, status, ...message }),
          };
        }
        return item;
      }),
    };
  },
  [receiveMessageFromAdmin]: (state, { payload }) => {
    const messages = mergeOrAppendNewMessages(state.messages, [
      {
        senderId: defaultIdForBot,
        senderType: get(payload, 'sender.isBot') ? SENDER_TYPES.BOT : SENDER_TYPES.ADMIN,
        message: getMessageFromPayload(payload),
      },
    ]);

    return {
      ...state,
      messages,
      // messages: shortifyMessages(messages),
      numberOfNewMessages: getNumberOfNewMessages(messages),
    };
  },
  [sendAttachmentRequest]: (state, { payload }) => {
    return {
      ...state,
      messages: [
        ...state.messages,
        {
          senderId: defaultIdForSubscriber,
          senderType: SENDER_TYPES.SUBSCRIBER,
          message: getMessageFromPayload(payload),
        },
      ],
    };
  },
  [sendAttachmentSuccess]: (state, action) => {
    // Old messages
    let listMessages = [...state.messages];
    // New messages
    let newMessages = get(action, ['payload', 'res']);
    // Append new messages
    (newMessages || []).map((newMessage) => {
      const externalId = get(newMessage, 'externalId');
      listMessages = listMessages.map((mes) => {
        const originalExternalId = get(mes, ['message', 'externalId']);
        if (Number(originalExternalId) === Number(externalId)) {
          return {
            senderId: defaultIdForSubscriber,
            senderType: SENDER_TYPES.SUBSCRIBER,
            message: getMessageFromPayload(get(newMessage, 'message')),
          };
        }
        return mes;
      });
    });
    return {
      ...state,
      messages: listMessages,
    };
  },
  [sendAttachmentError]: (state, action) => {
    return {
      ...state,
    };
  },
  [getMessagesRequest]: (state, { payload }) => {
    return {
      ...state,
      isLoadingMessage: true,
      isLoadingMessageHistory: payload.isLoadingMessageHistory,
    };
  },
  [getMessagesSuccess]: (state, { payload }) => {
    let messages = payload || [];
    if (isEmpty(messages))
      return {
        ...state,
        isLoadingMessage: true,
        isLoadingMessageHistory: false,
        numberOfNewMessages: getNumberOfNewMessages(state.message),
      };

    let newMessages = mergeOrAppendNewMessages(
      state.messages,
      messages.map((m) => {
        return {
          senderId: getSenderId(m),
          senderType: m.senderType,
          message: getMessageFromPayload({ id: m.id, status: m.status, ...m.message }),
        };
      }),
    );
    return {
      ...state,
      isLoadingMessage: true,
      isLoadingMessageHistory: false,
      messages: newMessages,
      numberOfNewMessages: getNumberOfNewMessages(newMessages),
    };
  },
  [resetNumberOfNewMessages]: (state) => {
    const lastMessage = state.messages.pop();
    return {
      ...state,
      messages: [...state.messages, { ...lastMessage, status: MESSAGE_STATUS.READ }],
      numberOfNewMessages: 0,
    };
  },
};

export default (state = initialState, action) => createReducer(messageReducer, state, action);
