import { wait } from '../../commons/utils';

const fibonacciNumbers = [0.5, 1, 2, 3, 5, 8, 13];
class WebRTC {
  peerConnection;
  candidates = [];
  iceServers = [];
  desc;
  remoteStream;
  localStream;
  gotRemoteStreamHandler = undefined;

  _resetProperties() {
    this.remoteStream = null;
    this.localStream = null;
  }

  _convertCandidateToJSON(candidate) {
    if (!candidate) return null;
    return {
      address: candidate.address,
      candidate: candidate.candidate,
      component: candidate.component,
      foundation: candidate.foundation,
      port: candidate.port,
      priority: candidate.priority,
      protocol: candidate.protocol,
      relatedAddress: candidate.relatedAddress,
      relatedPort: candidate.relatedPort,
      sdpMLineIndex: candidate.sdpMLineIndex,
      sdpMid: candidate.sdpMid,
      tcpType: candidate.tcpType,
      type: candidate.type,
      usernameFragment: candidate.usernameFragment,
    };
  }
  _initPeerConnection() {
    // console.log("_initPeerConnection:", this.iceServers);
    this.peerConnection = new RTCPeerConnection({
      iceServers: this.iceServers,
      iceTransportPolicy: 'all',
      iceCandidatePoolSize: '0',
    });

    this.peerConnection.ontrack = (e) => {
      // console.log("receive remote stream");
      // console.log("-----------------receive remote stream---------");
      this.remoteStream = e.streams[0];
      this.gotRemoteStreamHandler && this.gotRemoteStreamHandler(this.remoteStream);
    };

    // Some should read documents
    // https://www.html5rocks.com/en/tutorials/webrtc/infrastructure/#how-can-i-build-a-signaling-service
    // https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection
    // https://webrtc.github.io/samples/src/content/peerconnection/trickle-ice/
    // https://code.google.com/archive/p/coturn/wikis/turnserver.wiki
    this.peerConnection.onicegatheringstatechange = () => {
      // console.log(
      //   "onicegatheringstatechange: ",
      //   this.peerConnection.iceGatheringState
      // );
      // if (this.peerConnection.iceGatheringState !== "complete") {
      //   return;
      // }
    };

    this.peerConnection.onicecandidate = ({ candidate }) => {
      // console.log("onicecandidate:");
      candidate && this.candidates.push(this._convertCandidateToJSON(candidate));
    };

    this.peerConnection.onicecandidateerror = (err) => {
      // console.log("onicecandidateerror:", err);
    };

    this.peerConnection.oniceconnectionstatechange = (e) => {
      // console.log("ICE Event: ", e);
      // console.log(
      //   "iceConnectionState: ",
      //   this.peerConnection.iceConnectionState
      // );
    };
  }

  async _collectICECandicates() {
    // console.log("_collectICECandicates at ", new Date());
    const maxWaitDurations = fibonacciNumbers;
    let currentWaitTimeIndex = 0;
    let findRequiredICE = false;
    let findSRFLX = false;
    let findREPLAY = false;
    do {
      // console.log(
      //   "_collectICECandicates:",
      //   maxWaitDurations[currentWaitTimeIndex]
      // );
      await wait(maxWaitDurations[currentWaitTimeIndex] * 1000);
      for (const ice of this.candidates) {
        if (ice.type === 'srflx') {
          findSRFLX = true;
        }

        if (ice.type === 'relay') {
          findREPLAY = true;
        }
      }
      findRequiredICE = findSRFLX && findREPLAY;
      ++currentWaitTimeIndex;
    } while (!findRequiredICE && currentWaitTimeIndex < maxWaitDurations.length - 1);

    if (!findRequiredICE) throw new Error();
    if (!this) return;
    return { desc: this.desc, candidates: this.candidates };
  }

  async _initUserMedia() {
    // console.log("_initUserMedia");
    // Init local stream
    try {
      this.localStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      this.localStream.getTracks().forEach((track) => {
        // console.log("this.peerConnection.addTrack");
        this.peerConnection.addTrack(track, this.localStream);
      });
    } catch (ex) {
      throw new Error(JSON.stringify({ message: 'Init User Media failed', code: '2' }));
    }
  }

  _setRemotePeerConnectionInfo = async (remotePeerConnectionInfo) => {
    // console.log("_setRemotePeerConnectionInfo");
    const { desc, candidates } = remotePeerConnectionInfo || {};
    if (!desc || !candidates) return;
    // console.log("setRemoteDescription:", desc);
    await this.peerConnection.setRemoteDescription(new RTCSessionDescription(desc));
    for (const candidate of candidates) {
      // console.log("addIceCandidate:");
      await this.peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
    }
    // console.log("addIceCandidate:end");
    // await this.peerConnection.addIceCandidate({ candidate: "" });
  };

  async _createOffer() {
    // console.log("_createOffer");
    if (!this) return;
    this.desc = await this.peerConnection.createOffer({
      offerToReceiveAudio: 1,
    });
    await this.peerConnection.setLocalDescription(this.desc);
  }

  async _createAnswer() {
    // console.log("_createAnswer");
    if (!this) return;
    this.desc = await this.peerConnection.createAnswer();
    await this.peerConnection.setLocalDescription(this.desc);
  }

  async startCall(iceServers) {
    this.iceServers = iceServers;
    // console.log("startCall");
    return new Promise(async (resolve, reject) => {
      try {
        this._initPeerConnection();
        await this._initUserMedia();
        await this._createOffer();
        const { desc, candidates } = await this._collectICECandicates();
        resolve([null, { desc, candidates }]);
      } catch (ex) {
        // ToDo: Could not collect ICE Candicates -> Leave some information for call back
        reject([ex]);
      }
    });
  }

  async acceptCall(remotePeerConnectionInfo) {
    // console.log("acceptCall");
    return new Promise(async (resolve, reject) => {
      try {
        this._initPeerConnection();
        await this._initUserMedia();
        await this._setRemotePeerConnectionInfo(remotePeerConnectionInfo);
        await this._createAnswer();
        const { desc, candidates } = await this._collectICECandicates();
        resolve([null, { desc, candidates }]);
      } catch (ex) {
        // ToDo: Could not collect ICE Candicates -> Leave some information for call back
        reject([ex]);
      }
    });
  }

  stopCall() {
    // console.log("stop Call");
    if (!this) return;
    this.localStream &&
      this.localStream.getTracks().forEach((track) => {
        track.stop();
      });

    if (this.peerConnection) {
      this.peerConnection.close();
      this.peerConnection = undefined;
    }

    (this.candidates = []), (this.desc = []);
  }

  onGotRemoteStreamHandler(cb) {
    this.gotRemoteStreamHandler = cb;
  }

  async updateRemotePeerConnection(remotePeerConnectionInfo) {
    // console.log("updateRemotePeerConnection");
    this._setRemotePeerConnectionInfo(remotePeerConnectionInfo);
  }

  getLocalStream() {
    return this.localStream;
  }

  getRemoteStream() {
    return this.remoteStream;
  }
}

export default new WebRTC();
