import * as actions from './action-type';
import { createAction } from 'redux-actions';
import { get } from 'lodash';
import { sendMessage } from '../../connections/websocket';
import { GROUP, TYPE } from '../../commons/constants/websocket';
import { getTenantInfo } from '../../commons/tenant-info';
import { updateHasTriggeredWelcomeMessage } from './start-conversation.actions';
import {
  isDisablePreChatForm,
  isLastTimeMoreThanAPeriod,
  isTriggerWelcomeBackMessageWhenClickIcon,
  isTriggerWelcomeMessageWhenClickIcon,
} from '../../commons/utils';
import getTimeDuration from '../../commons/utils/get-time-duration';

export const onScrollToBottom = createAction(actions.ON_SCROLL_TO_BOTTOM);
export const toggleChatRequest = createAction(`${actions.TOGGLE_CHAT}.REQUEST`);

export const toggleTheSupportWidgetRequest = createAction(`${actions.TOGGLE_SUPPORT_WIDGET}.REQUEST`);

export function toggleChat(setting) {
  return async function (dispatch, getState) {
    const showChat = get(getState(), 'behavior.showChat');
    dispatch(toggleChatRequest(!showChat));

    if (!setting.isEnableAutomation) {
      console.log('Ingore because not enable bot now');
      return;
    }

    if (showChat) return;

    const state = getState();
    const hasTriggeredWelcomeMessage = get(state, 'behavior.hasTriggeredWelcomeMessage');
    const alreadyStartConversation = get(state, 'behavior.alreadyStartConversation');

    if (!isDisablePreChatForm(setting) && !alreadyStartConversation) {
      console.log('Ingore because the pre-chat form is enable & the conversation has not started');
      return;
    }

    if (!isTriggerWelcomeMessageWhenClickIcon(setting) && !isTriggerWelcomeBackMessageWhenClickIcon(setting)) {
      console.log('Ingore because dont enable welcome message & welcome back message when revisit');
      return;
    }

    if (isTriggerWelcomeMessageWhenClickIcon(setting) && !hasTriggeredWelcomeMessage) {
      sendMessage({
        group: GROUP.MESSAGE,
        type: TYPE.MESSAGE.TRIGGER_WELCOME_MESSAGE,
        payload: {},
        tenantInfo: getTenantInfo(),
      });
      dispatch(updateHasTriggeredWelcomeMessage());
      return;
    }

    if (hasTriggeredWelcomeMessage && isTriggerWelcomeBackMessageWhenClickIcon(setting)) {
      const delay = getTimeDuration(
        get(setting, 'screenWelcomeBackMessages.trigger.time'),
        get(setting, 'screenWelcomeBackMessages.trigger.unitOfTime'),
      );
      console.log('...Trigger the welcome back message when click if:', delay);
      if (isLastTimeMoreThanAPeriod(get(state, 'behavior.lastTimeTriggeredWelcomeMessage'), delay)) {
        console.log('Trigger the welcome back message');
        sendMessage({
          group: GROUP.MESSAGE,
          type: TYPE.MESSAGE.TRIGGER_WELCOME_BACK_MESSAGE,
          payload: {},
          tenantInfo: getTenantInfo(),
        });
        dispatch(updateHasTriggeredWelcomeMessage());
      }
      return;
    }
  };
}

export function toggleCall() {
  return {
    type: actions.TOGGLE_CALL,
  };
}

export function settingSound() {
  return {
    type: actions.SOUND_SETTING,
  };
}

export const updateMediaPermission = createAction(`${actions.UPDATE_MEDIA_PERMISSION}.REQUEST`);

export const selectDevices = createAction(`${actions.SELECT_DEVICE}.REQUEST`);
