export const CALL_STATUS = {
  ACCESS_REQUEST: 'Access-request',

  CONNECTING: 'Connecting',
  START_CALL: 'Start-call',
  QUEUED: 'Queued',
  INITIATED: 'Initiated',
  RINGING: 'Ringing',
  IN_PROGRESS: 'In-progress',
  RECONNECTING: 'Reconnecting',
  COMPLETED: 'Completed',
  COMPLETED_RATING: 'Completed-rating',
  BUSY: 'Busy',
  BUSY_LEAVE_INFO: 'Busy-leave-info',
  NO_ANSWER: 'No-answer',
  ADMIN_CANCELED: 'Admin-canceled',
  CUSTOMER_CANCELED: 'Customer-canceled',
  FAILED: 'Failed',
  NO_SUPPORT: 'No-support',
};

export const CALL_ERROR = {
  LOCAL_DEVICE_NOT_SUPPORT: '10',
};

export const CALL_STOP_REASON = {
  SUBSCRIBER_STOP: 'SUBSCRIBER_STOP',
  SUBSCRIBER_AUTO_STOP_WHEN_TIMEOUT: 'SUBSCRIBER_AUTO_STOP_WHEN_TIMEOUT',
};
