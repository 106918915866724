const publicIp = require('public-ip');

export default async () => {
  try {
    return (await publicIp.v4()) || (await publicIp.v6());
  } catch (ex) {
    console.log('exception when detect ip: ', ex);
    return;
  }
};
