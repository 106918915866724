export const GROUP = {
  MESSAGE: 1,
  CALL: 2,
};

export const TYPE = {
  MESSAGE: {
    NEW_MESSAGE_FROM_CHATBOX: 1,
    NEW_MESSAGE_FROM_ADMIN: 2,
    START_CONVERSATION: 3,
    UPDATE_MESSAGE_STATUS: 4,
    TRIGGER_STARTING_POINT: 5,
    TRIGGER_WELCOME_MESSAGE: 6,
    CREATED_MESSAGE: 7,
    TRIGGER_WELCOME_BACK_MESSAGE: 8,
  },
  CALL: {
    REQUEST_ICE_SERVERS: -1,
    REQUEST_ICE_SERVERS_RES: 0,
    START_CALL: 1,
    RECEIVE_CALL_FROM_ADMIN: 2,
    RECEIVE_ADMIN_PEER_CONNECTION: 3,
    UPDATE_PEER_CONNECTION_STATUS: 4,
    RECEIVE_UPDATE_CALL_STATUS: 5,
    STOP_CALL: 6,
    UPDATE_CALL_INFORMATION: 7,
    LEAVE_INFORMATION: 8,
    UPDATE_IN_CALL_SIGNAL: 9,
  },
};

export const BUTTON_STARTS = {
  START_CONVERSATION: 'START_CONVERSATION',
  LEAVE_MESSAGE: 'LEAVE_MESSAGE',
  CHAT_WITH_BOT: 'CHAT_WITH_BOT',
};
