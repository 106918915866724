import * as ls from "local-storage";

export const getLSVariable = (name) => {
  try {
    return ls(name);
  } catch (ex) {
    return null;
  }
};

export const setLSVariable = (name, value) => {
  try {
    ls(name, value);
    return true;
  } catch (ex) {
    return false;
  }
};

export const removeLSVariable = (name) => {
  try {
    ls.remove(name);
    return true;
  } catch (ex) {
    return false;
  }
};
