import { createAction } from 'redux-actions';
import { GROUP, TYPE } from '../../commons/constants/websocket';
import { getTenantInfo } from '../../commons/tenant-info';
import { sendMessage } from '../../connections/websocket';
import { MESSAGE_STATUS } from '../../commons/constants/message-status';
import { get } from 'lodash';
import { resetNumberOfNewMessages } from '../actions/message.actions';

export const receiveMessageFromAdmin = createAction(`WS_${GROUP.MESSAGE}_${TYPE.MESSAGE.NEW_MESSAGE_FROM_ADMIN}`);

export const receiveCreatedMessageNotification = createAction(`WS_${GROUP.MESSAGE}_${TYPE.MESSAGE.CREATED_MESSAGE}`);

export const receiveUpdateCallStatus = createAction(`WS_${GROUP.CALL}_${TYPE.CALL.RECEIVE_UPDATE_CALL_STATUS}`);

export const receiveICEServerResponse = createAction(`WS_${GROUP.CALL}_${TYPE.CALL.REQUEST_ICE_SERVERS_RES}`);

// Emit message status after received
export const sendAckReceivingMessage = (payload, channelId) => {
  return async function (dispatch, getState) {
    // console.log('sendAckReceivingMessage: 1', payload, channelId);
    const showChat = get(getState(), 'behavior.showChat');
    if (document.hidden === false && showChat) {
      _sendMessage({ ...payload, status: MESSAGE_STATUS.READ }, getState(), channelId);
      dispatch(resetNumberOfNewMessages());
    } else {
      _sendMessage({ ...payload, status: MESSAGE_STATUS.DELIVERIED }, getState(), channelId);
      // Call function listen event visibilityChange
      _handleVisibilityChange(payload, getState(), channelId, dispatch);
    }
  };
};

const _sendMessage = (payload) => {
  sendMessage({
    group: GROUP.MESSAGE,
    type: TYPE.MESSAGE.UPDATE_MESSAGE_STATUS,
    payload,
    tenantInfo: getTenantInfo(),
  });
};

const _handleVisibilityChange = (payload, store, channelId, dispatch) => {
  // console.log('_handleVisibilityChange', payload);
  const showChat = get(store, 'behavior.showChat');
  function handleVisibilityChange() {
    if (!document.hidden && showChat) {
      _sendMessage({ ...payload, status: MESSAGE_STATUS.READ }, store, channelId);
      dispatch(resetNumberOfNewMessages());
    }
  }
  document.addEventListener('visibilitychange', handleVisibilityChange, {
    once: true,
  });
};
