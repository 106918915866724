import { isEmpty, get } from 'lodash';

export const isDisablePreChatForm = (setting) => {
  const value = get(setting, 'chatboxThemeSettings.preChatForm.enable');
  if (typeof value === 'undefined' || value === false) return true;
  return false;
};

export const isTriggerWelcomeBackMessageWhenClickIcon = (setting) => {
  return get(setting, 'screenWelcomeBackMessages.trigger.triggerType') === 'click-icon';
};

export const isTriggerWelcomeMessageWhenClickIcon = (setting) => {
  const value = get(setting, 'screenWelcomeMessages.trigger.triggerType');
  return typeof value === 'undefined' || value === 'click-icon';
};

export const isTriggerWelcomeBackMessageWhenVisitPage = (setting) => {
  return get(setting, 'screenWelcomeBackMessages.trigger.triggerType') === 'visit-page';
};

export const isTriggerWelcomeMessageWhenVisitPage = (setting) => {
  return get(setting, 'screenWelcomeMessages.trigger.triggerType') === 'visit-page';
};

export const isLastTimeMoreThanOneDay = (lastTime) => {
  if (isEmpty(lastTime)) return false;

  const fromDate = typeof lastTime === 'string' ? new Date(lastTime) : lastTime;
  return Math.abs(fromDate - new Date()) >= 1000 * 60 * 60 * 24;
};

export const isLastTimeMoreThanAPeriod = (lastTime, duration) => {
  if (typeof lastTime === 'undefined' || typeof duration === 'undefined') return false;
  const fromDate = typeof lastTime === 'string' ? new Date(lastTime) : lastTime;
  return Math.abs(fromDate - new Date()) >= duration;
};

const DURATION_TO_RESET_DECISION = 30 * 60 * 1000;
// const DURATION_TO_RESET_DECISION = 20 * 1000;

export const getCurrentScreen = (props) => {
  const {
    isInConfigurationMode,
    isStartConversationFromAdmin,
    demoData,
    setting,
    alreadySubmitUserInfo,
    isInOperatingHours,
    isEnableAutomation,
    lastTimeAcceptChattingWithBot,
    lastTimeAcceptLeavingOfflineMessage,
    lastTimeReceivingMessageFromAdmin,
  } = props;

  if (isInConfigurationMode) {
    return demoData.currentScreen === 'welcome_messages' ? 'conversation' : demoData.currentScreen;
  }

  if (
    isStartConversationFromAdmin &&
    lastTimeReceivingMessageFromAdmin &&
    !isLastTimeMoreThanAPeriod(lastTimeReceivingMessageFromAdmin, DURATION_TO_RESET_DECISION)
  ) {
    return 'conversation';
  }

  if (!isDisablePreChatForm(setting) && !alreadySubmitUserInfo) {
    return 'form';
  }

  if (!isInOperatingHours) {
    if (
      isEnableAutomation &&
      (!lastTimeAcceptChattingWithBot || isLastTimeMoreThanAPeriod(lastTimeAcceptChattingWithBot, DURATION_TO_RESET_DECISION))
    ) {
      return 'off_with_chatbot';
    }

    if (
      !isEnableAutomation &&
      (!lastTimeAcceptLeavingOfflineMessage || isLastTimeMoreThanAPeriod(lastTimeAcceptLeavingOfflineMessage, DURATION_TO_RESET_DECISION))
    ) {
      return 'off';
    }
  }
  return 'conversation';
};

export const wait = async (duration) => {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, duration);
  });
};

export const retryFuncWithFibonancies = async (funcChecking, fiboNumbers) => {
  const fibonanciNumbers = fiboNumbers || [0.5, 1, 2, 3, 5, 8, 13];
  let currentWaitTimeIndex = 0;
  do {
    await wait(fibonanciNumbers[currentWaitTimeIndex] * 1000);
    ++currentWaitTimeIndex;
  } while (!funcChecking() && currentWaitTimeIndex < fibonanciNumbers.length - 1);
  if (!funcChecking()) throw new Error();
};
