import { getLSVariable } from "./utils/local-storage";
import { LOCAL_STORAGE_VARIABLES, getLocalStorageVariables } from "./constants/local-storage";
let channelId;
let tenantId;
let conversationId;

export const setChannelId = (value) => {
  // console.log("setChannelId: ", value);
  channelId = value;
};

export const setTenantId = (value) => {
  // console.log("setTenantId: ", value);
  tenantId = value;
};
export const setConversationId = (value) => {
  // console.log("setConversationId: ", value);
  conversationId = value;
};

export const getTenantInfo = () => {
  return {
    tenantId: tenantId || getLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.TENANT_ID, channelId)),
    channelId: channelId || getLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.CHANNEL_ID, channelId)),
    conversationId: conversationId || getLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.CONVERSATION_ID, channelId)),
  };
};
