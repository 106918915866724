import * as actions from './action-type';
import { createAction } from 'redux-actions';

export const saveUserInfoRequest = createAction(`${actions.SAVE_USER_INFO}.REQUEST`);

export const saveUserInfo = (name, email, phone) => {
  return async function (dispatch) {
    const reqPayload = { name, email, phone };
    await dispatch(saveUserInfoRequest(reqPayload));
  };
};
