import * as actions from './action-type';
import { createAction } from 'redux-actions';
import { sendMessage, triggerStartingPoint } from '../../connections/websocket';
import { BUTTON_STARTS, GROUP, TYPE } from '../../commons/constants/websocket';
import { getTenantInfo } from '../../commons/tenant-info';
import { get, isEmpty } from 'lodash';
import { saveUserInfo } from './save-user-info.actions';
import runFunAfter from '../../commons/utils/run-fun-after';
import getTimeDuration from '../../commons/utils/get-time-duration';
import {
  isDisablePreChatForm,
  isLastTimeMoreThanOneDay,
  isTriggerWelcomeMessageWhenVisitPage,
  isTriggerWelcomeBackMessageWhenVisitPage,
  isLastTimeMoreThanAPeriod,
} from '../../commons/utils';
import { STARTING_POINT_TYPES } from '../../commons/constants/starting-point-types';
import { find } from 'lodash';
import { sendTextMessage } from './message.actions';

export const startConversationRequest = createAction(`${actions.START_CONVERSATION}.REQUEST`);

export const updateHasTriggeredWelcomeMessage = createAction(`${actions.UPDATE_HAS_TRIGGERED_WELCOME_MESSAGE}.SUCCESS`);

export const trackingTheTriggerEvent = createAction(`${actions.TRACK_THE_TRIGGER_EVENT}.SUCCESS`);

export const startConversation = (fromButton, { name, email, phone, isWorkingDay, leaveMessage }) => {
  return async function (dispatch) {
    await dispatch(startConversationRequest({ name, email, phone, fromButton, isWorkingDay }));

    if (!isEmpty(name) || !isEmpty(email) || !isEmpty(phone)) {
      await dispatch(saveUserInfo(name, email, phone));
    }

    fromButton === BUTTON_STARTS.START_CONVERSATION &&
      sendMessage({
        group: GROUP.MESSAGE,
        type: TYPE.MESSAGE.START_CONVERSATION,
        payload: { displayName: name, email, phone },
        tenantInfo: getTenantInfo(),
      });

    // Check whether it's needed to trigger the welcome message
    if (fromButton === BUTTON_STARTS.CHAT_WITH_BOT || (fromButton === BUTTON_STARTS.START_CONVERSATION && isWorkingDay)) {
      console.log('Trigger the welcome message');
      runFunAfter(() => {
        sendMessage({
          group: GROUP.MESSAGE,
          type: TYPE.MESSAGE.TRIGGER_WELCOME_MESSAGE,
          payload: {},
          tenantInfo: getTenantInfo(),
        });
        dispatch(updateHasTriggeredWelcomeMessage());
      }, 200);
    }

    if (fromButton === BUTTON_STARTS.LEAVE_MESSAGE) {
      console.log('BUTTON_STARTS.LEAVE_MESSAGE: ', leaveMessage);
      runFunAfter(() => {
        dispatch(sendTextMessage(leaveMessage));
      }, 200);
    }
  };
};

export const triggerTheStartingPoint = (startingPointType, startingPointId, setting) => {
  return async function (dispatch, getState) {
    console.log('triggerTheStartingPoint:', startingPointId);
    if (!setting.isEnableAutomation) {
      console.log('Ingore because not enable bot now');
      return;
    }

    const state = getState();
    const alreadyStartConversation = get(state, 'behavior.alreadyStartConversation');
    const trackingTriggerEvents = get(state, 'behavior.triggerStartingPointTracking') || [];

    // If enable the pre-chat form, only trigger the startingPoint after starting the conversation.
    if (!isDisablePreChatForm(setting) && !alreadyStartConversation) {
      console.log('ingore the action');
      return;
    }

    if (startingPointType === STARTING_POINT_TYPES.VISITOR_CLICK_ELEMENT) {
      console.log('triggerTheStartingPoint');
      triggerStartingPoint(startingPointId);
      return;
    }

    if (startingPointType === STARTING_POINT_TYPES.VISITOR_VISIT_URLS) {
      // console.log('startingPointType === STARTING_POINT_TYPES.VISITOR_VISIT_URLS');
      const { lastTime } = find(trackingTriggerEvents, (event) => event.startingPointId === startingPointId) || {};
      // console.log('lastTime:', lastTime);
      // console.log('isLastTimeMoreThanOneDay: ', isLastTimeMoreThanOneDay(lastTime));
      if (isEmpty(lastTime) || isLastTimeMoreThanOneDay(lastTime)) {
        // console.log('trigger');
        triggerStartingPoint(startingPointId);
        dispatch(trackingTheTriggerEvent({ startingPointType, startingPointId }));
      }
    }
  };
};

export const triggerTheWelcomeMessageWhenVisitPage = (setting) => {
  return async function (dispatch, getState) {
    console.log('triggerTheWelcomeMessageWhenVisitPage');
    const state = getState();
    if (!setting.isEnableAutomation) {
      console.log('Ingore because not enable bot now');
      return;
    }

    const hasTriggeredWelcomeMessage = get(state, 'behavior.hasTriggeredWelcomeMessage');
    const alreadyStartConversation = get(state, 'behavior.alreadyStartConversation');
    if (!isDisablePreChatForm(setting) && !alreadyStartConversation) {
      console.log('Ingore because the pre-chat form is enable & the conversation has not started'); // When enable the pre-chat form, only start conversation when click to chat with bot
      return;
    }

    if (!isTriggerWelcomeMessageWhenVisitPage(setting) && !isTriggerWelcomeBackMessageWhenVisitPage(setting)) {
      console.log('Ingore because dont enable welcome message & welcome back message when revisit');
      return;
    }

    if (isTriggerWelcomeMessageWhenVisitPage(setting) && !hasTriggeredWelcomeMessage) {
      // Trigger the welcome message
      const delay = getTimeDuration(get(setting, 'screenWelcomeMessages.trigger.time'), get(setting, 'screenWelcomeMessages.trigger.unitOfTime'));
      console.log('...Trigger the welcome message: ', delay);
      setTimeout(() => {
        console.log('Trigger the welcome message');
        sendMessage({
          group: GROUP.MESSAGE,
          type: TYPE.MESSAGE.TRIGGER_WELCOME_MESSAGE,
          payload: {},
          tenantInfo: getTenantInfo(),
        });
        dispatch(updateHasTriggeredWelcomeMessage());
      }, delay);
      return;
    }

    if (hasTriggeredWelcomeMessage && isTriggerWelcomeBackMessageWhenVisitPage(setting)) {
      // Trigger the welcome back message
      const delay = getTimeDuration(
        get(setting, 'screenWelcomeBackMessages.trigger.time'),
        get(setting, 'screenWelcomeBackMessages.trigger.unitOfTime'),
      );
      console.log('...Trigger the welcome back message:', delay);
      if (isLastTimeMoreThanAPeriod(get(state, 'behavior.lastTimeTriggeredWelcomeMessage'), delay)) {
        console.log('Trigger the welcome back message');
        sendMessage({
          group: GROUP.MESSAGE,
          type: TYPE.MESSAGE.TRIGGER_WELCOME_BACK_MESSAGE,
          payload: {},
          tenantInfo: getTenantInfo(),
        });
        dispatch(updateHasTriggeredWelcomeMessage());
      }
      return;
    }
  };
};

// - Nếu enable pre-chat form (Tức là yêu cầu có thông tin mới bắt đầu chat)
// ---- Nếu in operating hours
// -------- Nếu enable chatbot -> Gửi welcome message.
// ---- Nếu not in operating hours
// -------- Nếu enable automation
// --------- Show chat with bot -> Gửi welcome message.
// -------- Nếu not enable automation
// --------- Show leave message -> Không gửi welcome message.

// - Nếu không enable pre-chat form
// ---- Nếu in operating hours
//
// ---- Nếu not in operating hours
