import getPublicIp from './get-public-ip';
import wait from './wait';
const { detect } = require('detect-browser');

async function getInfo() {
  try {
    const moment = await import('moment-timezone');
    const browser = detect() || {};
    const { os, name } = browser;
    const urlParams = new URLSearchParams(window.location.search);
    return {
      browser,
      ip: await getPublicIp(),
      timezoneName: moment.tz.guess(),
      timezoneUTC: -(new Date().getTimezoneOffset() / 60),
      utmSource: urlParams.get('utm_source'),
      utmMedium: urlParams.get('utm_medium'),
      utmCampaign: urlParams.get('utm_campaign'),
      utmTerm: urlParams.get('utm_term'),
      utmContent: urlParams.get('utm_content'),
      utmGrowthToolId: 'CALL_LIVECHAT',
      utmBrowser: name,
      utmOS: os,
    };
  } catch (error) {
    return {};
  }
}

export default async () => {
  // Don't wait too long for the 3rd party response.
  return (await Promise.race([getInfo(), wait(3000)])) || {};
};
