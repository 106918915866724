import * as actions from './action-type';
import { createAction } from 'redux-actions';
import { sendMessage } from '../../connections/websocket';
import { GROUP, TYPE } from '../../commons/constants/websocket';
import { getTenantInfo } from '../../commons/tenant-info';
import webRTC from '../../components/phone-call/webrtc';
import { CALL_STATUS, CALL_STOP_REASON } from '../../components/phone-call/constants';
import { get } from 'lodash';

export const stopCallRequest = createAction(`${actions.STOP_CALL}.REQUEST`);
export const stopCall = (callInfo, { stopReason, totalDuration }) => {
  return async function (dispatch) {
    // webRTC stop call
    webRTC.stopCall();
    if (!get(callInfo, 'id')) return;

    const payload = {
      ...callInfo,
      stopReason,
      totalDuration: stopReason === CALL_STOP_REASON.SUBSCRIBER_AUTO_STOP_WHEN_TIMEOUT ? 0 : totalDuration,
      status:
        stopReason === CALL_STOP_REASON.SUBSCRIBER_AUTO_STOP_WHEN_TIMEOUT || callInfo.status !== CALL_STATUS.IN_PROGRESS
          ? CALL_STATUS.CUSTOMER_CANCELED
          : CALL_STATUS.COMPLETED,
    };

    dispatch(stopCallRequest({ status: payload.status }));
    sendMessage({
      group: GROUP.CALL,
      type: TYPE.CALL.STOP_CALL,
      payload,
      tenantInfo: getTenantInfo(),
    });
  };
};
