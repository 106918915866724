import * as actions from './action-type';
import { createAction } from 'redux-actions';
import { sendMessage } from '../../connections/websocket';
import { GROUP, TYPE } from '../../commons/constants/websocket';
import { MESSAGE_TYPES } from '../../commons/constants/message-type';
import { getTenantInfo } from '../../commons/tenant-info';
import * as FormData from 'form-data';
import { getLSVariable } from '../../commons/utils/local-storage';
import { LOCAL_STORAGE_VARIABLES, getLocalStorageVariables } from '../../commons/constants/local-storage';
import { includes, get, size, join } from 'lodash';
import { MESSAGE_STATUS } from '../../commons/constants/message-status';

export const sendTextMessageRequest = createAction(`${actions.SEND_TEXT_MESSAGE}.REQUEST`);
export const sendTextMessageSuccess = createAction(`${actions.SEND_TEXT_MESSAGE}.SUCCESS`);
export const sendTextMessage = (text) => {
  return async function (dispatch, store) {
    // console.log("sendTextMessage:", text, getTenantInfo());
    const reqPayload = { type: MESSAGE_TYPES.TEXT, content: { text }, externalId: new Date().getTime() };
    await dispatch(sendTextMessageRequest(reqPayload));
    sendMessage({
      group: GROUP.MESSAGE,
      type: TYPE.MESSAGE.NEW_MESSAGE_FROM_CHATBOX,
      payload: reqPayload,
      tenantInfo: getTenantInfo(),
    });
  };
};

export const sendAttachmentRequest = createAction(`${actions.SEND_ATTACHMENT}.REQUEST`);

export const sendAttachmentSuccess = createAction(`${actions.SEND_ATTACHMENT}.SUCCESS`);

export const sendAttachmentError = createAction(`${actions.SEND_ATTACHMENT}.ERROR`);

export const sendAttachment = (text, files, channelKey) => {
  return function (dispatch, store) {
    // console.log("sendAttachment.text: ", text);
    // console.log("sendAttachment.files: ", files);
    const isSimulator = getLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.IS_SIMULATOR, channelKey));
    // Get tenantId ,conversationId, channelId
    let channelId = getLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.CHANNEL_ID, channelKey));
    let tenantId = getLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.TENANT_ID, channelKey));
    let conversationId = getLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.CONVERSATION_ID, channelKey));
    // console.log("sendAttachment", channelId, tenantId, conversationId);
    const form = new FormData();
    form.append('tenantId', tenantId);
    form.append('channelId', channelId);
    form.append('conversationId', conversationId);

    let listUUIDOrder = [];
    files.map((file) => {
      form.append('files', file.originalFile);

      let reqPayload;
      const isImage = get(file, 'isImage');
      const externalId = new Date().getTime();
      // console.log(`externalId`, externalId);

      isImage
        ? (reqPayload = {
            type: MESSAGE_TYPES.IMAGE,
            content: {
              url: null,
            },
            externalId,
          })
        : (reqPayload = {
            type: MESSAGE_TYPES.FILE,
            content: {
              name: file.name,
              url: null,
              type: get(file, ['originalFile', 'type']),
              size: get(file, ['originalFile', 'size']),
            },
            externalId,
          });

      listUUIDOrder = isEmpty(listUUIDOrder) ? [externalId] : [...listUUIDOrder, externalId];
      dispatch(sendAttachmentRequest(reqPayload));
    });

    form.append('listUUIDOrder', join(listUUIDOrder, ','));
    if (!isEmpty(text)) {
      form.append('type', MESSAGE_TYPES.TEXT);
      form.append('content', text);

      const reqPayload = { type: MESSAGE_TYPES.TEXT, content: { text } };
      dispatch(sendAttachmentRequest(reqPayload));
    }
    // Submit form data
    const host = getLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.HOST, channelKey));
    const request = new XMLHttpRequest();
    request.open('POST', `${host}/conversations/messages-from-chatbox`);

    request.onreadystatechange = function () {
      if (request.readyState === 4) {
        const res = JSON.parse(get(request, 'response'));
        const error = get(res, 'error');
        if (error) {
          dispatch(sendAttachmentError({ res }));
          return;
        }
        dispatch(sendAttachmentSuccess({ res }));
      }
    };
    request.send(form);
  };
};

export const sendButtonMessage = (buttonData) => {
  return async function (dispatch, store) {
    const externalId = new Date().getTime();
    const reqPayload = {
      type: MESSAGE_TYPES.TEXT,
      content: { text: get(buttonData, 'label') },
      externalId,
    };

    await dispatch(sendTextMessageRequest(reqPayload));
    sendMessage({
      group: GROUP.MESSAGE,
      type: TYPE.MESSAGE.NEW_MESSAGE_FROM_CHATBOX,
      payload: {
        type: 'button',
        content: buttonData,
        externalId,
      },
      tenantInfo: getTenantInfo(),
    });
  };
};

const _sendMessage = (payload) => {
  sendMessage({
    group: GROUP.MESSAGE,
    type: TYPE.MESSAGE.UPDATE_MESSAGE_STATUS,
    payload,
    tenantInfo: getTenantInfo(),
  });
};

const _updateLastMessageStatus = ({ lastMessage, getState, dispatch }) => {
  if (!lastMessage) return;
  const lastMessageReceiveStatus = get(lastMessage, 'status');
  const senderType = get(lastMessage, 'senderType');
  const showChat = get(getState(), 'behavior.showChat');
  if (senderType !== 1 && !includes([MESSAGE_STATUS.READ, MESSAGE_STATUS.FAILED], lastMessageReceiveStatus) && showChat && !document.hidden) {
    _sendMessage({ ...lastMessage, status: MESSAGE_STATUS.READ });
    dispatch(resetNumberOfNewMessages());
  }
};

export const getMessagesRequest = createAction('GET_MESSAGES_REQUEST');
export const getMessagesSuccess = createAction('GET_MESSAGES_SUCCESS');
export const getMessagesError = createAction('GET_MESSAGES_ERROR');
export const getMessages = (isLoadingMessageHistory, channelKey) => {
  return async (dispatch, getState) => {
    const isSimulator = getLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.IS_SIMULATOR, channelKey));

    const persistentState = getLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.STORE_STATE, channelKey));
    const conversationId = getLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.CONVERSATION_ID, channelKey));
    const tenantId = getLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.TENANT_ID, channelKey));
    const channelId = getLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.CHANNEL_ID, channelKey));
    const host = getLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.HOST, channelKey));

    const messages = get(persistentState, ['messages', 'messages']);
    const reqPayload = {
      conversationId,
      tenantId,
      channelId,
      isLoadingMessageHistory,
    };
    dispatch(getMessagesRequest(reqPayload));

    const request = new XMLHttpRequest();
    request.open(
      'GET',
      `${host}/messages/widget_messages?conversationId=${conversationId}&channelId=${channelId}&tenantId=${tenantId}&pageSize=10${
        isLoadingMessageHistory ? `&skip=${size(messages)}` : ''
      }`,
    );

    request.onreadystatechange = function () {
      if (request.readyState === 4) {
        const res = JSON.parse(get(request, 'response'));
        const error = get(res, 'error');
        if (error) {
          dispatch(getMessagesError({ res }));
          return;
        }
        dispatch(getMessagesSuccess(res, reqPayload));
        const lastMessage = res[0];
        !isLoadingMessageHistory && lastMessage && _updateLastMessageStatus({ lastMessage, getState, dispatch });
      }
    };
    request.send();
  };
};

export const resetNumberOfNewMessages = createAction('RESET_NUMBER_OF_NEW_MESSSAGES');
