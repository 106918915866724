import * as actions from "./action-type";
import { sendMessage } from "../../connections/websocket";
import { GROUP, TYPE } from "../../commons/constants/websocket";
import { getTenantInfo } from "../../commons/tenant-info";
import { createAction } from "redux-actions";

export const leaveInformationSuccess = createAction(`${actions.LEAVE_INFORMATION}.SUCCESS`);
export const leaveInformation = (payload, channelId) => {
  return async function (dispatch) {
    dispatch(leaveInformationSuccess(payload));
    sendMessage({
      group: GROUP.CALL,
      type: TYPE.CALL.LEAVE_INFORMATION,
      payload,
      tenantInfo: getTenantInfo(),
    });
  };
};
