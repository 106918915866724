import * as actions from './action-type';
import { createAction } from 'redux-actions';
import { sendMessage } from '../../connections/websocket';
import { GROUP, TYPE } from '../../commons/constants/websocket';
import { getTenantInfo } from '../../commons/tenant-info';
import { get, isArray, isEmpty } from 'lodash';
import { retryFuncWithFibonancies } from '../../commons/utils';
import webRTC from '../../components/phone-call/webrtc';
import getClientInfo from '../../commons/utils/get-client-info';

export const startCallRequest = createAction(`${actions.START_CALL}.REQUEST`);
export const startCallSuccess = createAction(`${actions.START_CALL}.SUCCESS`);
export const startCallError = createAction(`${actions.START_CALL}.ERROR`);
export const startCall = (device) => {
  return async function (dispatch, getState) {
    await dispatch(startCallRequest({ device }));
    const tenantInfo = getTenantInfo();

    // Request ICE Server
    sendMessage({
      group: GROUP.CALL,
      type: TYPE.CALL.REQUEST_ICE_SERVERS,
      tenantInfo,
    });
    try {
      // Synchronously wait here for checking when the ICE Servers is availables
      // Retry checking when ICE Servers
      await retryFuncWithFibonancies(() => {
        // console.log("Trying to connect to server");
        // To test what happen if could not get ice server
        // return false;
        const iceServers = get(getState(), 'behavior.callInfo.callData.iceServers');
        // console.log("iceServers: ", iceServers);
        const result = isArray(iceServers) && !isEmpty(iceServers);
        return result;
      }, [0.5, 1, 2, 3, 5, 8]);

      const [error, peerConnectionInfo] = await webRTC.startCall(get(getState(), 'behavior.callInfo.callData.iceServers'));
      if (!error) {
        sendMessage({
          group: GROUP.CALL,
          type: TYPE.CALL.START_CALL,
          payload: {
            peerConnectionInfo,
            callerInfo: await getClientInfo(),
          },
          tenantInfo,
        });
      } else {
        await dispatch(startCallError({ error: 'Could not establish the connection' }));
        // console.log("Could not establish the connection");
      }
    } catch (ex) {
      // Reach max retry
      await dispatch(startCallError({ error: 'Could not connect to server' }));
      // console.log("Could not connect to server:", ex);
    }
  };
};
