import * as actions from './action-type';
import { createAction } from 'redux-actions';
import { isEmpty } from 'lodash';

function getDeviceList(cb) {
  // console.log("getDeviceList");
  try {
    function gotDevices(devices) {
      // console.log("gotDevices: ", JSON.stringify(devices));
      cb(devices || []);
    }

    function handleError(error) {
      // console.log("gotDevices-handleError: ", error);
      cb([]);
    }

    navigator.mediaDevices.enumerateDevices().then(gotDevices).catch(handleError);
  } catch (ex) {
    cb([]);
  }
}

export const checkMediaDeviceRequest = createAction(`${actions.CHECK_MEDIA_DEVICE}.REQUEST`);

export const checkMediaDeviceSuccess = createAction(`${actions.CHECK_MEDIA_DEVICE}.SUCCESS`);

export const checkMediaDevice = () => {
  // console.log("checkMediaDevice");
  return async function (dispatch, store) {
    await dispatch(checkMediaDeviceRequest());
    getDeviceList((devices) => {
      let totalAudioInput = 0;
      let totalAudioOutput = 0;
      let isAllowAcessMedia = false;
      for (const device of devices) {
        const { kind, label } = device || {};
        if (kind === 'audioinput') {
          totalAudioInput++;
        }

        if (kind === 'audiooutput') {
          totalAudioOutput++;
        }

        if (!isAllowAcessMedia && !isEmpty(label)) {
          isAllowAcessMedia = true;
        }
      }

      dispatch(
        checkMediaDeviceSuccess({
          totalAudioInput,
          totalAudioOutput,
          devices,
          isAllowAcessMedia,
        }),
      );
    });
  };
};
