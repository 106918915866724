export default function getTimeDuration(value, unit) {
  if (!value) return 0;

  const localUnit = unit || 'seconds';

  if (localUnit === 'milliseconds') {
    return Number(value);
  }

  if (localUnit === 'seconds') {
    return Number(value) * 1000;
  }

  if (localUnit === 'minutes') {
    return Number(value) * 60 * 1000;
  }

  if (localUnit === 'hours') {
    return Number(value) * 60 * 60 * 1000;
  }

  return 0;
}
