import { createReducer } from '../../commons/utils/store';
import * as actionTypes from '../actions/action-type';
import {
  startConversationRequest,
  checkMediaDeviceSuccess,
  updateMediaPermission,
  startCallRequest,
  startCallError,
  startCallSuccess,
  receiveUpdateCallStatus,
  selectDevices,
  stopCallRequest,
  receiveMessageFromAdmin,
  leaveInformationSuccess,
  saveUserInfoRequest,
  receiveICEServerResponse,
  updateHasTriggeredWelcomeMessage,
  toggleChatRequest,
  trackingTheTriggerEvent,
  toggleTheSupportWidgetRequest,
} from '../actions';
import { CALL_STATUS } from '../../components/phone-call/constants';
import webrtc from '../../components/phone-call/webrtc';
import { updateCallInfoRequest } from '../actions/update-call.action';
import { BUTTON_STARTS } from '../../commons/constants/websocket';
import { findIndex, get } from 'lodash';

function isFacebookApp() {
  var ua = navigator.userAgent || navigator.vendor || window.opera;
  return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
}

const initialState = {
  showChat: false,
  showSupportWidget: false,
  isStartConversationFromAdmin: false,
  hasTriggeredWelcomeMessage: false,
  lastTimeTriggeredWelcomeMessage: undefined,
  lastTimeAcceptChattingWithBot: undefined,
  lastTimeAcceptLeavingOfflineMessage: undefined,
  lastTimeReceivingMessageFromAdmin: undefined,
  alreadySubmitUserInfo: false,
  alreadyStartConversation: false,
  callInfo: {
    selectedSpeakerId: undefined,
    selectedMicrophoneId: undefined,
    isShowCall: false,
    completeCheckingDevice: false,
    isAllowAccessMicrophone: false,
    isDeviceSupport: false,
    devices: [],
    missedDevices: [],
    userRejectsPermission: undefined,
  },
  userInfo: {
    name: undefined,
    email: undefined,
    phone: undefined,
  },
  soundOn: true,
  numberOfNewMessages: 0,
  triggerStartingPointTracking: [],
};

const behaviorReducer = {
  [toggleTheSupportWidgetRequest]: (state, { payload }) => {
    const status = get(state, 'callInfo.callData.status');
    const isCalling = status === CALL_STATUS.INITIATED || status === CALL_STATUS.IN_PROGRESS || status === CALL_STATUS.RECONNECTING;

    // Don't change if calling
    if (isCalling) {
      return state;
    }

    return {
      ...state,
      showSupportWidget: payload,
      showChat: payload === false ? false : state.showChat,
      callInfo: {
        ...(state.callInfo || {}),
        isShowCall: payload === false ? false : get(state, 'callInfo.isShowCall'),
        callData: undefined,
      },
    };
  },
  [toggleChatRequest]: (state) => {
    return {
      ...state,
      showChat: !state.showChat,
    };
  },

  [startConversationRequest]: (state, { payload: { fromButton, isWorkingDay } }) => {
    return {
      ...state,
      alreadyStartConversation: true,
      hasTriggeredWelcomeMessage: fromButton === BUTTON_STARTS.START_CONVERSATION && isWorkingDay ? true : state.hasTriggeredWelcomeMessage,
      lastTimeAcceptChattingWithBot: fromButton === BUTTON_STARTS.CHAT_WITH_BOT ? new Date() : state.lastTimeAcceptChattingWithBot,
      lastTimeAcceptLeavingOfflineMessage: fromButton === BUTTON_STARTS.LEAVE_MESSAGE ? new Date() : state.lastTimeAcceptLeavingOfflineMessage,
    };
  },

  [updateHasTriggeredWelcomeMessage]: (state) => {
    return {
      ...state,
      hasTriggeredWelcomeMessage: true,
      lastTimeTriggeredWelcomeMessage: new Date(),
    };
  },

  [saveUserInfoRequest]: (state, { payload }) => {
    return {
      ...state,
      userInfo: {
        ...state.userInfo,
        name: get(payload, 'name'),
        email: get(payload, 'email'),
        phone: get(payload, 'phone'),
      },
      alreadySubmitUserInfo: true,
    };
  },

  [trackingTheTriggerEvent]: (state, { payload: { startingPointType, startingPointId } }) => {
    console.log('trackingTheTriggerEvent: ');
    const existOneIndex = findIndex(state.triggerStartingPointTracking, (event) => event.startingPointId === startingPointId);
    let triggerStartingPointTracking = state.triggerStartingPointTracking || [];
    if (existOneIndex >= 0) {
      triggerStartingPointTracking[existOneIndex] = {
        ...triggerStartingPointTracking[existOneIndex],
        lastTime: new Date(),
      };
    } else {
      triggerStartingPointTracking.push({
        startingPointType,
        startingPointId,
        lastTime: new Date(),
      });
    }

    const now = new Date();
    const oneDayInMilleseconds = 1000 * 60 * 60 * 24;
    return {
      ...state,
      triggerStartingPointTracking: triggerStartingPointTracking.filter((event) => Math.abs(now - event.lastTime) < oneDayInMilleseconds),
    };
  },

  [actionTypes.TOGGLE_CALL]: (state) => {
    // console.log("toggleCall");
    const isShowing = get(state, 'callInfo.isShowCall');
    const status = get(state, 'callInfo.callData.status');
    const isCalling = status === CALL_STATUS.INITIATED || status === CALL_STATUS.IN_PROGRESS || status === CALL_STATUS.RECONNECTING;

    if (isCalling) {
      return state;
    }

    return {
      ...state,
      callInfo: {
        ...state.callInfo,
        isShowCall: !isShowing,
        callData: undefined,
      },
    };
  },

  [updateMediaPermission]: (state, { payload }) => {
    const isAllow = payload;
    return {
      ...state,
      callInfo: {
        ...state.callInfo,
        isAllowAccessMicrophone: isAllow,
        userRejectsPermission: !isAllow,
      },
    };
  },

  [checkMediaDeviceSuccess]: (state, { payload }) => {
    // console.log("payload", payload);
    const { totalAudioInput, totalAudioOutput, devices, isAllowAcessMedia } = payload;

    const getMissedDevices = () => {
      const results = [];
      if (totalAudioInput === 0) {
        results.push('input');
      }

      if (totalAudioOutput === 0) {
        results.push('output');
      }
    };
    return {
      ...state,
      callInfo: {
        ...state.callInfo,
        devices: devices,
        isAllowAccessMicrophone: isAllowAcessMedia,
        isDeviceSupport:
          !/iphone|ipod|ipad|ucbrowser/.test(window.navigator.userAgent.toLowerCase()) &&
          !isFacebookApp() &&
          (totalAudioInput > 0 || totalAudioOutput > 0),
        missedDevices: getMissedDevices(),
      },
    };
  },
  [startCallRequest]: (state, { payload }) => {
    return {
      ...state,
      callInfo: {
        ...state.callInfo,
        callData: {
          status: CALL_STATUS.CONNECTING,
          subscriberPeerConnectionInfo: get(payload, 'subscriberPeerConnectionInfo'),
        },
      },
    };
  },

  [startCallSuccess]: (state, { payload }) => {
    return {
      ...state,
      callInfo: {
        ...state.callInfo,
        callData: {
          status: CALL_STATUS.QUEUED,
          subscriberPeerConnectionInfo: get(payload, 'subscriberPeerConnectionInfo'),
        },
      },
    };
  },

  [startCallError]: (state, { payload }) => {
    return {
      ...state,
      callInfo: {
        ...state.callInfo,
        callData: {
          error: get(payload, 'error'),
          status: CALL_STATUS.FAILED,
        },
      },
    };
  },

  [receiveICEServerResponse]: (state, { payload }) => {
    return {
      ...state,
      callInfo: {
        ...state.callInfo,
        callData: {
          ...state.callInfo.callData,
          iceServers: payload,
        },
      },
    };
  },

  [receiveUpdateCallStatus]: (state, { payload }) => {
    // console.log("receiveUpdateCallStatus: ", payload);
    const status = get(payload, ['status']);
    if (
      status === CALL_STATUS.COMPLETED ||
      status === CALL_STATUS.ADMIN_CANCELED ||
      status === CALL_STATUS.BUSY ||
      status === CALL_STATUS.NO_ANSWER
    ) {
      // console.log("STOP CALL");
      webrtc.stopCall();
    }
    return {
      ...state,
      callInfo: {
        ...state.callInfo,
        callData: payload,
      },
    };
  },
  [updateCallInfoRequest]: (state, { payload }) => {
    return {
      ...state,
      callInfo: {
        ...state.callInfo,
        callData: { ...state.callInfo.callData, ...payload },
      },
    };
  },
  [leaveInformationSuccess]: (state, { payload }) => {
    return {
      ...state,
      userInfo: {
        ...state.userInfo,
        name: get(payload, 'name'),
        email: get(payload, 'email'),
        phone: get(payload, 'phone'),
      },
      alreadySubmitUserInfo: true,
    };
  },
  [selectDevices]: (state, { payload: { speakerId, microphoneId } }) => {
    return {
      ...state,
      callInfo: {
        ...state.callInfo,
        selectedSpeakerId: speakerId,
        selectedMicrophoneId: microphoneId,
      },
    };
  },
  [stopCallRequest]: (state, { payload }) => {
    return {
      ...state,
      callInfo: {
        ...state.callInfo,
        callData: {
          ...state.callInfo.callData,
          status: payload.status,
        },
      },
    };
  },
  [receiveMessageFromAdmin]: (state, { payload }) => {
    return {
      ...state,
      isStartConversationFromAdmin: state.alreadyStartConversation === false,
      lastTimeReceivingMessageFromAdmin: new Date(),
    };
  },
  [actionTypes.SOUND_SETTING]: (state) => {
    return {
      ...state,
      soundOn: !state.soundOn,
    };
  },
};

export default (state = initialState, action) => createReducer(behaviorReducer, state, action);
