import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { get, find, cloneDeep, tolower } from 'lodash';
import behavior from './store/reducers/behavior.reducer';
import messages from './store/reducers/message.reducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  initWSClient,
  closeWSClient,
  setMessageHanlder,
  sendWebsiteEvent,
  disconnectWSClient,
  reconnectWSClient,
} from '../src/connections/websocket';
import WidgetContext from './widget-context';
import { setLSVariable, getLSVariable } from './commons/utils/local-storage';
import { setChannelId, setTenantId, setConversationId } from './commons/tenant-info';
import { LOCAL_STORAGE_VARIABLES, getLocalStorageVariables } from './commons/constants/local-storage';
import { WEBSITE_EVENTS } from './commons/constants/website-events';
import { GROUP, TYPE } from './commons/constants/websocket';
import { STARTING_POINT_TYPES } from './commons/constants/starting-point-types';
import { sendAckReceivingMessage } from './store/actions/ws.actions';
import clearEndSlash from './commons/utils/clear-end-slash';
import runFunAfter from './commons/utils/run-fun-after';
import { NetworkDetector } from './commons/utils/network-detector';
import { triggerTheStartingPoint, triggerTheWelcomeMessageWhenVisitPage } from './store/actions/start-conversation.actions';
const Widget = lazy(() => import('./components/widget'));
const Conversation = lazy(() => import('./components/conversation'));

const getHrefWithoutQueryAndParam = () => {
  return `${window.location.origin}${window.location.pathname}`;
};

const getListStartingPoints = async (host, channelId, templateId) => {
  const results = [];
  let pageIndex = 1;
  const pageSize = 10;
  let totalReturnedItems = 0;
  do {
    const response = await fetch(`${host}/channel/website/starting-points?channelId=${channelId}&templateId=${templateId}&pageIndex=${pageIndex}`, {
      method: 'GET',
    });
    ++pageIndex;
    const returnedItems = await response.json();
    Array.from(returnedItems || []).forEach((item) => {
      if (item.active) {
        results.push(item);
      }
    });
    totalReturnedItems = returnedItems.length;
  } while (totalReturnedItems === pageSize);
  return results;
};

function findValidStartingPoint(startingPoints, type, opts) {
  for (const startPoint of startingPoints) {
    if (type === STARTING_POINT_TYPES.VISITOR_CLICK_ELEMENT) {
      const { id, className } = opts;
      if (id && id === get(startPoint, 'detail.id')) return startPoint;
      if (className && className === get(startPoint, 'detail.className')) return startPoint;
    }

    if (type === STARTING_POINT_TYPES.VISITOR_VISIT_URLS) {
      const { url } = opts;
      // console.log("url:", url);
      const requiredUrls = get(startPoint, 'detail.urls') || [];
      const lowercaseUrlWithoutEndSlash = toLower(clearEndSlash(url));
      // console.log("lowercaseUrlWithoutEndSlash:", lowercaseUrlWithoutEndSlash);
      if (find(requiredUrls, (defineUrl) => toLower(clearEndSlash(defineUrl)) === lowercaseUrlWithoutEndSlash)) {
        // console.log("found starting point");
        return startPoint;
      }
    }
  }
  return null;
}

const generateDefaultIframeStyles = (styleSheetLink, widgetStylesheet) => {
  return `${styleSheetLink ? `<link rel="stylesheet" href="${styleSheetLink}"></link>` : ''}
  <style type="text/css" class="iframe-boostcrm">
    .frame-content {
      height: 100%;
    }  
    body {
      font-family: 'Roboto !important';
    }
    body {
      background: none !important;
      background-color: transparent !important;    
      overflow:hidden
    }
    ${widgetStylesheet || ''}
  </style>`;
};
export default class ConnectedWidget extends React.Component {
  constructor(props) {
    super(props);
    const { isInConfigurationMode, isSimulator, onInitConversationSuccess, setting, styleSheetLink } = this.props;
    const { host, tenantId, channelId, automation } = setting;
    const isDisableLiveChat = !get(setting, 'chatboxComponent.enable');
    const isDisableCall = !get(setting, 'callComponent.enable');

    this.state = {
      online: true,
      isReconnecting: false,
      isConnectFailed: false,
      iframeStylesInnerHtml: '',
      iframeStyles: generateDefaultIframeStyles(styleSheetLink),
    };

    const reducer = combineReducers({ behavior, messages });
    if (isInConfigurationMode) {
      this.store = createStore(
        function () {},
        process.env.NODE_ENV !== 'production' ? composeWithDevTools(applyMiddleware(thunk)) : applyMiddleware(thunk),
      );
      return;
    }

    const styleTag = props.pageDocument.head.querySelector('.boostcrm-stylesheet');
    if (styleTag) {
      const observer = new MutationObserver(() => {
        this.setState({
          iframeStylesInnerHtml: this.state.iframeStylesInnerHtml + styleTag.innerHTML,
          iframeStyles: generateDefaultIframeStyles(styleSheetLink, this.state.iframeStylesInnerHtml + styleTag.innerHTML),
        });
        styleTag.innerHTML = '';
      });
      observer.observe(styleTag, { characterData: false, childList: true, attributes: false });
    }

    setTenantId(tenantId);
    setChannelId(channelId);
    setLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.IS_SIMULATOR, channelId), isSimulator);
    let persistentState = getLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.STORE_STATE, channelId));
    setLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.CHANNEL_ID, channelId), channelId);
    setLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.TENANT_ID, channelId), tenantId);
    setLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.HOST, channelId), host);

    if (isSimulator) {
      persistentState = {};
    }
    if (get(persistentState, ['behavior', 'callInfo', 'callData'])) {
      persistentState.behavior.callInfo.callData = null;
    }
    this.store = createStore(
      reducer,
      persistentState || {},
      process.env.NODE_ENV !== 'production' ? composeWithDevTools(applyMiddleware(thunk)) : applyMiddleware(thunk),
    );

    if (this.store) {
      this.store.subscribe(() => {
        const data = cloneDeep(this.store.getState());
        setLSVariable(getLocalStorageVariables(LOCAL_STORAGE_VARIABLES.STORE_STATE, channelId), data);
      });
    }

    if (!isSimulator && isDisableCall && isDisableLiveChat) return;

    initWSClient(
      host,
      this.props.setting.channelToken,
      channelId,
      isSimulator,
      null,
      (newConversationId) => {
        // console.log("Start new conversation: ", newConversationId);
        setConversationId(newConversationId);
        newConversationId && onInitConversationSuccess && onInitConversationSuccess(newConversationId);
      },
      () => {
        console.log('set online false 1');
        this.setState({
          online: false,
          isConnectFailed: true,
          isReconnecting: false,
        });
      },
      () => {
        console.log('set online false 2');
        this.setState({
          online: false,
          isConnectFailed: false,
          isReconnecting: true,
        });
      },
      () => {
        console.log('set online true');
        this.setState({
          online: true,
          isConnectFailed: false,
          isReconnecting: false,
        });
      },
    );

    setMessageHanlder((message) => {
      const actionType = `WS_${get(message, 'group')}_${get(message, 'type')}`;
      this.store.dispatch({
        type: actionType,
        payload: message.payload,
      });

      if (get(message, 'group') === GROUP.MESSAGE && get(message, 'type') === TYPE.MESSAGE.NEW_MESSAGE_FROM_ADMIN) {
        this.store.dispatch(sendAckReceivingMessage(message.payload, channelId));
      }
    });

    if (!isSimulator) {
      sendWebsiteEvent(WEBSITE_EVENTS.VISIT_URL, {
        url: getHrefWithoutQueryAndParam(),
        loadDate: new Date(),
        tenantInfo: { channelId },
      });

      runFunAfter(() => {
        this.store.dispatch && this.store.dispatch(triggerTheWelcomeMessageWhenVisitPage(setting));
      }, 1000);

      // Get list of starting points here.
      const { enabled, templateId } = automation || {};
      if (enabled && templateId) {
        getListStartingPoints(host, setting.channelId, templateId).then((startingPoints) => {
          if (startingPoints.length === 0) return;

          // Check if needed to trigger event 'visit-urls'
          const startingPointForVisitURLs = findValidStartingPoint(startingPoints, STARTING_POINT_TYPES.VISITOR_VISIT_URLS, {
            url: getHrefWithoutQueryAndParam(),
          });

          startingPointForVisitURLs &&
            runFunAfter(() => {
              this.store.dispatch &&
                this.store.dispatch(triggerTheStartingPoint(STARTING_POINT_TYPES.VISITOR_VISIT_URLS, startingPointForVisitURLs.id, setting));
            }, 1000);

          const { pageDocument } = props;
          if (pageDocument) {
            pageDocument.addEventListener(
              'click',
              (e) => {
                if (!e) return;
                const toElement = e.toElement;
                if (!toElement) return;
                const id = toElement.id;
                const className = toElement.className;
                const validStartPoint = findValidStartingPoint(startingPoints, STARTING_POINT_TYPES.VISITOR_CLICK_ELEMENT, {
                  id,
                  className,
                });
                validStartPoint &&
                  this.store.dispatch &&
                  this.store.dispatch(triggerTheStartingPoint(STARTING_POINT_TYPES.VISITOR_CLICK_ELEMENT, validStartPoint.id, setting));
              },
              true,
            );
          }
        });
      }
    }
  }

  networkChangesHandler = (isOnline) => {
    if (!isOnline) {
      disconnectWSClient();
    } else {
      reconnectWSClient();
    }
  };

  componentWillUnmount() {
    closeWSClient();
  }

  render() {
    const { setting, isSimulator, isInConfigurationMode } = this.props;
    const { online, isReconnecting, isConnectFailed, iframeStyles } = this.state;
    return (
      <Provider store={this.store}>
        <WidgetContext.Provider
          value={{
            setting,
            isSimulator,
            online,
            isReconnecting,
            isConnectFailed,
            isInConfigurationMode,
          }}
        >
          {isSimulator && (
            <Suspense fallback={<div></div>}>
              <Conversation
                online={online}
                isReconnecting={isReconnecting}
                isConnectFailed={isConnectFailed}
                isSimulator={true}
                isMobileMode={true}
                iframeStyles={iframeStyles}
                rect={{ width: 500, height: 600 }}
                {...this.props}
              />
            </Suspense>
          )}
          {!isSimulator && (
            <Suspense fallback={<div></div>}>
              <Widget {...this.props} iframeStyles={iframeStyles} rootWindow={window} rootDocument={document} />
            </Suspense>
          )}
          <NetworkDetector onChange={this.networkChangesHandler} />
        </WidgetContext.Provider>
      </Provider>
    );
  }
}
