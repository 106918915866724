import { getLSVariable } from "../utils/local-storage";
export function getLocalStorageVariables(type, channelId) {
  const prefix = `kot_bs_chatbox_ls`;
  const isSimulator = getLSVariable(`${prefix}.${channelId}.IS_SIMULATOR`);
  const lastPrefix = isSimulator ? `${prefix}.TEST` : prefix;

  switch (type) {
    case LOCAL_STORAGE_VARIABLES.CHANNEL_ID:
      return `${lastPrefix}.${channelId}.CHANNEL_ID`;
    case LOCAL_STORAGE_VARIABLES.TENANT_ID:
      return `${lastPrefix}.${channelId}.TENANT_ID`;
    case LOCAL_STORAGE_VARIABLES.CONVERSATION_ID:
      return `${lastPrefix}.${channelId}.CONVERSATION_ID`;
    case LOCAL_STORAGE_VARIABLES.STORE_STATE:
      return `${lastPrefix}.${channelId}.STORE_STATE`;
    case LOCAL_STORAGE_VARIABLES.HOST:
      return `${lastPrefix}.${channelId}.HOST`;
    case LOCAL_STORAGE_VARIABLES.IS_SIMULATOR:
      return `${prefix}.${channelId}.IS_SIMULATOR`;
  }
}

export const LOCAL_STORAGE_VARIABLES = {
  CHANNEL_ID: `CHANNEL_ID`,
  TENANT_ID: `TENANT_ID`,
  CONVERSATION_ID: `CONVERSATION_ID`,
  STORE_STATE: `STORE_STATE`,
  HOST: `HOST`,
  IS_SIMULATOR: `IS_SIMULATOR`
};
